<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title v-if="editar">
          Editar Impressora *
        </v-toolbar-title>
        <v-toolbar-title v-else>
          Nova Impressora *
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSave"
        >
          <v-icon class="white--text">
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-row v-if="!editar">
            <v-col
              cols="7"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="impressoradados.cdImpressora"
                label="Código da Impressora"
                :rules="cdImpressoraRules"
                :counter="40"
                required
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="7"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="impressoradados.nmImpressora"
                label="Nome do Impressora"
                :rules="nmImpressoraRules"
                :counter="40"
                required
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-select
                v-if="carregarCombo"
                v-model="impressoradados.tpImpressora"
                :items="tpImpressora"
                item-text="descricao"
                item-value="codigo"
                label="Tipo"
                dense
                outlined
                hide-details
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="7"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="impressoradados.nmMarcaFiscal"
                label="Marca Impressora"
                :rules="marcaImpressoraRules"
                :counter="40"
                required
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="7"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="impressoradados.nrEquipamento"
                label="Equipamento da Impressora"
                :rules="equipamentoImpressoraRules"
                :counter="1"
                required
                type="number"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="7"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="impressoradados.nrSerie"
                label="Série Impressora"
                :rules="serieImpressoraRules"
                :counter="1"
                required
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-card>
</template>
<script>
  import impressoraRep from './../../components/services/impressoraRepository'

  export default {
    props: {
      editar: {
        type: Boolean,
        required: true,
      },
      id: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        impressoradados: {
          cdImpressora: '',
          nrEquipamento: 0,
        },
        valid: false,
        cdImpressoraRules: [
          v => !!v || 'Name obrigatorio',
          v => v.length <= 40 || 'Quantidade de Caracteres superior que o necessario',
        ],
        nmImpressoraRules: [
          v => !!v || 'Name obrigatorio',
          v => v.length <= 15 || 'Quantidade de Caracteres superior que o necessario',
        ],
        marcaImpressoraRules: [
          v => !!v || 'Name obrigatorio',
          v => v.length <= 15 || 'Quantidade de Caracteres superior que o necessario',
        ],
        equipamentoImpressoraRules: [
          v => !!v || 'Name obrigatorio',
        ],
        serieImpressoraRules: [
          v => !!v || 'Name obrigatorio',
          v => v.length <= 1 || 'Quantidade de Caracteres superior que o necessario',
        ],
        tpImpressora: [{
          codigo: 'N',
          descricao: 'NOTA',
        }, {
          codigo: 'C',
          descricao: 'CUPOM',
        }],
        carregarCombo: false,
      }
    },
    async created () {
      await this.getList()
      // var dadosThis = this.caixadados
      setTimeout(() => {
        this.carregarCombo = true
      }, 1000)
    },
    methods: {
      onSair () {
        this.$emit('onSairCadastro')
      },
      async getList () {
        if (this.editar) {
          try {
            this.$store.commit('setSplashScreen', true)
            await impressoraRep.getId(this.id).then(response => {
              if (response.data.status === 'sucesso') {
                this.impressoradados = response.data.dados
              } else if (response.data.status === 'mensagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.dados,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$swal({
                icon: 'error',
                text: error,
              })
            })
          } catch (error) {

          }
        }
      },
      onSave () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setSplashScreen', true)
          if (this.editar === false) {
            this.impressoradados.editar = false
            this.impressoradados.nrEquipamento = parseInt(this.impressoradados.nrEquipamento)
            impressoraRep.Save(this.impressoradados).then(response => {
              if (response.data.status === 'sucesso') {
                this.$emit('onSucesso')
              } else if (response.data.status === 'mensagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: error,
              })
            })
          } else {
            this.$store.commit('setSplashScreen', true)
            this.impressoradados.editar = true
            this.impressoradados.nrEquipamento = parseInt(this.impressoradados.nrEquipamento)
            impressoraRep.Save(this.impressoradados).then(response => {
              // listaUsuarios = response.data
              if (response.data.status === 'sucesso') {
                this.$emit('onSucesso')
              } else if (response.data.status === 'mensagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: error,
              })
            })
          }
        }
      },
    },
  }
</script>
