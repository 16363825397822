<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Lista de Impressoras"
      class="px-5 py-3"
    >
      <v-row>
        <v-col class="text-right">
          <v-btn
            outlined
            @click="onNovoCadastro"
          >
            Cadastrar
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          Lista de Impressoras
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          dense
          :headers="headers"
          :items="listaImpressora"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  color="blue"
                  @click="onEditar(item.cdImpressora)"
                  v-on="on"
                >
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="red"
                  v-bind="attrs"
                  class="ml-3"
                  @click="onExcluir(item)"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </base-material-card>
    <v-row justify="center">
      <v-dialog
        v-model="dialogNovo"
        persistent
        max-width="800"
      >
        <modalImpressora
          v-if="dialogNovo"
          :id="idImpressora"
          :editar="editar"
          @onSairCadastro="onSairCadastro"
          @onSucesso="onSucesso"
        />
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="dialogExcluir"
      persistent
      max-width="800"
    >
      <modalImpressoraExcluir
        v-if="dialogExcluir"
        :id="idImpressora"
        :nome="nomeImpressora"
        @onSairCadastro="onSairCadastro"
        @onSucesso="onSucesso"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import impressoraRep from './../../components/services/impressoraRepository.js'
  import modalImpressora from './../../pages/componentes/componente-impressora-cadastro.vue'
  import modalImpressoraExcluir from './../../pages/componentes/componente-impressora-excluir.vue'

  export default {
    components: {
      modalImpressora,
      modalImpressoraExcluir,
    },
    data () {
      return {
        search: '',
        listaImpressora: [],
        headers: [
          {
            text: 'Código',
            align: 'center',
            value: 'cdImpressora',
            width: 80,
          },
          {
            text: 'Nome Impressora',
            align: 'start',
            value: 'nmImpressora',
          },
          {
            text: 'Tipo Impressora',
            align: 'start',
            value: 'tipoImpDesc',
          },
          {
            text: 'Marca',
            align: 'start',
            value: 'nmMarcaFiscal',
          },
          {
            text: 'Equipamento',
            align: 'start',
            value: 'nrEquipamento',
          },
          {
            text: 'Série',
            align: 'start',
            value: 'nrSerie',
          },
          { text: '', value: 'actions', sortable: false },
        ],
        editar: false,
        idImpressora: '',
        nomeImpressora: '',
        // Dialogs
        dialogNovo: false,
        dialogExcluir: false,
      }
    },
    async created () {
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        this.$swal({
          icon: 'warning',
          text: 'Acesso não liberado!',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) this.$router.replace('/v1')
        })
      } else {
        await this.getList()
      }
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await impressoraRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaImpressora = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSairCadastro () {
        this.dialogNovo = false
        this.dialogExcluir = false
      },
      onSucesso () {
        this.dialogNovo = false
        this.dialogExcluir = false
        this.getList()
      },
      onNovoCadastro () {
        this.dialogNovo = true
        this.idImpressora = ''
        this.editar = false
      },
      onEditar (id) {
        this.idImpressora = id.trim()
        this.editar = true
        this.dialogNovo = true
      },
      onExcluir (item) {
        this.idImpressora = item.cdImpressora.trim()
        this.nomeImpressora = item.nmImpressora
        this.dialogExcluir = true
      },
    },
  }
</script>
